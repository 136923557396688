<template>
  <!-- Related Products start -->
  <div class="bee-content-block feature-product-block pt0">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="title-bg title-bg-left">Related Products</h3>
        </div>
        <div class="feature-product-4">
          <div class="feature-product-item">
            <div class="single-product">
              <img src="../assets/images/product-1.jpg" alt="Beekeeping Hive" />
              <div class="p-top-price">$40 - $49</div>
              <div class="product-hover">
                <h4>Beekeeping Hive</h4>
                <div class="prod-hover-price"><b>Price:</b>$40 - $49</div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <div class="product-action">
                  <a href="" title="" class="icon-view"><i class="fa fa-eye"></i></a
                  ><a href="" title="" class="icon-view"><i class="fa fa-heart-o"></i></a
                  ><a href="" title="" class="icon-view"><i class="fa fa-cart-plus"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="feature-product-item">
            <div class="single-product">
              <img src="../assets/images/product-2.jpg" alt="Beekeeping Hive" />
              <div class="p-top-price">$32 - $39</div>
              <div class="product-hover">
                <h4>Beekeeping Smoker</h4>
                <div class="prod-hover-price"><b>Price:</b>$32 - $39</div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <div class="product-action">
                  <a href="" title="" class="icon-view"><i class="fa fa-eye"></i></a
                  ><a href="" title="" class="icon-view"><i class="fa fa-heart-o"></i></a
                  ><a href="" title="" class="icon-view"><i class="fa fa-cart-plus"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="feature-product-item">
            <div class="single-product">
              <img src="../assets/images/product-3.jpg" alt="Beekeeping Hive" />
              <div class="p-top-price">$29 - $32</div>
              <div class="product-hover">
                <h4>Bee Brush</h4>
                <div class="prod-hover-price"><b>Price:</b>$29 - $32</div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <div class="product-action">
                  <a href="" title="" class="icon-view"><i class="fa fa-eye"></i></a
                  ><a href="" title="" class="icon-view"><i class="fa fa-heart-o"></i></a
                  ><a href="" title="" class="icon-view"><i class="fa fa-cart-plus"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="feature-product-item">
            <div class="single-product">
              <img src="../assets/images/product-4.jpg" alt="Beekeeping Hive" />
              <div class="p-top-price">$36 - $43</div>
              <div class="product-hover">
                <h4>Bee jackate</h4>
                <div class="prod-hover-price"><b>Price:</b>$36 - $43</div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <div class="product-action">
                  <a href="" title="" class="icon-view"><i class="fa fa-eye"></i></a
                  ><a href="" title="" class="icon-view"><i class="fa fa-heart-o"></i></a
                  ><a href="" title="" class="icon-view"><i class="fa fa-cart-plus"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="feature-product-item">
            <div class="single-product">
              <img src="../assets/images/product-2.jpg" alt="Beekeeping Hive" />
              <div class="p-top-price">$32 - $39</div>
              <div class="product-hover">
                <h4>Beekeeping Smoker</h4>
                <div class="prod-hover-price"><b>Price:</b>$32 - $39</div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <div class="product-action">
                  <a href="" title="" class="icon-view"><i class="fa fa-eye"></i></a
                  ><a href="" title="" class="icon-view"><i class="fa fa-heart-o"></i></a
                  ><a href="" title="" class="icon-view"><i class="fa fa-cart-plus"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Related Products end -->
</template>

<script>
export default {
  name: "RelatedProducts",

  mounted() {
    // eslint-disable-next-line no-undef
    $(".feature-product-4").owlCarousel({
      items: 4,
      loop: true,
      dots: false,
      nav: true,
      navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
      autoplay: true,
      smartSpeed: 1200,
      autoplayHoverPause: true,
      responsiveClass: true,
      touchDrag: true,
      responsive: {
        0: {
          items: 1,
        },
        680: {
          items: 2,
        },
        992: {
          items: 4,
        },
      },
    });
  },
};
</script>

<style></style>
