<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs title="Product" />
  <!-- Breadcroumbs end -->
  <!-- Product start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="product-details-top">
        <div class="row">
          <!-- Product Zoom start -->
          <div class="col-12 col-lg-6">
            <div class="p-details-zoom">
              <div class="p-zoom-big-images">
                <div class="easyzoom easyzoom--with-thumbnails">
                  <a
                    href="../assets/images/p-details-big.jpg"
                    class="gallery_link"
                    title="#popup_gallery"
                  >
                    <img
                     src="../assets/images/p-details-big.jpg"
                      alt="Zoom Product 1"
                      class="zoom-big-img"
                    />
                  </a>
                </div>
              </div>
              <div id="popup_gallery" style="display: none">
                <a
                  v-for="(image, index) in product.images"
                  :key="index"
                  href="../assets/images/p-details-big.jpg"
                >
                  <img
                    :src="image"
                    alt="Zoom Product 1"
                /></a>

              </div>
              <div class="zoom-thambnail">
                <ul class="thumbnails" id="p-details-thumb-slide">
                  <li
                    v-for="(image, index) in product.images"
                    :key="index"
                    class="highlight"
                  >
                    <a
                      href="image"
                    >
                      <img :src="image" alt="Zoom Product 1" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Product Zoom End -->

          <!-- Product Overdiew start -->
          <div class="col-12 col-lg-6">
            <div class="p-details-title">
              <h4>{{ product.name }}</h4>
              <div class="review-star">
                <i class="fa fa-star"></i><i class="fa fa-star"></i
                ><i class="fa fa-star"></i><i class="fa fa-star"></i
                ><i class="fa fa-star-half-empty"></i><span>(99 vote)</span> 120
                order
              </div>
            </div>
            <div class="p-item p-item-price">
              <div class="p-item-title">Price:</div>
              <div class="p-item-main text-linethrough">
                {{ product.price }}/piece
              </div>
            </div>
            <div class="p-item p-item-price">
              <div class="p-item-title">Discount Price:</div>
              <div class="p-item-main">
                <strong>{{ product.price }}</strong
                >/piece
              </div>
            </div>
            <div class="p-item p-item-color">
              <div class="p-item-title">Item Color:</div>
              <div class="p-item-main">
                <span class="color-defalt"></span
                ><span class="color-dark"></span><span class="color-sky"></span>
              </div>
            </div>
            <div class="p-item p-item-shipping">
              <div class="p-item-title">Shipping:</div>
              <div class="p-item-main">
                <b>Not shipping for this product</b>
              </div>
            </div>
            <div class="p-item p-item-quentity">
              <div class="p-item-title">Quantity:</div>
              <div class="p-item-main">
                <div class="quentity">
                  <i class="fa fa-minus"></i>
                  <input
                    type="text"
                    class="form-control"
                    value="1"
                    name="quentity"
                  />
                  <i class="fa fa-plus"></i>
                </div>
                piece (600 pieces available)
              </div>
            </div>
            <div class="p-item p-item-total-price">
              <div class="p-item-title">Total Price:</div>
              <div class="p-item-main"><strong>$35.00</strong></div>
            </div>
            <div class="p-item p-item-buttonGroup">
              <a href="" title="" class="bee-button">Buy Now</a
              ><a href="" title="" class="bee-button btn-gray">Add to cart</a>
            </div>
            <div class="p-item p-item-wishlist">
              <a href="" title=""
                ><i class="fa fa-heart-o"></i> Add to Wish List</a
              >
              <span>(17141 Adds)</span>
            </div>
            <div class="p-item p-item-returnPolicy">
              <div class="p-item-title">Return Policy:</div>
              <div class="p-item-main">
                Returns accepted if product not as described, buyer pays return
                shipping fee; or keep the product & agree refund with seller.
                <a href="">Read more</a>
              </div>
            </div>
            <div class="p-item p-item-payment">
              <div class="p-item-title">Payment:</div>
              <div class="p-item-main">
                <i class="fa fa-cc-paypal"></i><i class="fa fa-cc-visa"></i
                ><i class="fa fa-cc-mastercard"></i><i class="fa fa-cc-amex"></i
                ><i class="fa fa-cc-discover"></i>
              </div>
            </div>
          </div>
          <!-- Product Overview End -->
        </div>
      </div>
      <!-- Product Details start -->
      <div class="p-tab-section">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              href="#product_details"
              role="tab"
              data-toggle="tab"
              >Product Details</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#feedback" role="tab" data-toggle="tab"
              >Feedback(20)</a
            >
          </li>
        </ul>
        <!-- Tab panes -->
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane active" id="product_details">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <h5>INCLUDES (ALL COMPONENTS PRE-DRILLED):</h5>
            <ul>
              <li>
                Roof featuring shingled design and high pitch for optimal rain
                runoff
              </li>
              <li>Quilt box with canvas quilt</li>
              <li>1 bag of shredded cedar to fill quilt box</li>
              <li>Extra canvas to go between quilt box and top bars</li>
              <li>2 Warre boxes with windows and black walnut closures</li>
              <li>16 one piece top bars with wedge comb guides</li>
              <li>Standard screened bottom with closable insert</li>
              <li>
                Solid feet to elevate the hive off the ground and improve
                stability
              </li>
              <li>Stainless steel screws</li>
              <li>Assembly diagram and instructions</li>
            </ul>
            <h5>TOOLS REQUIRED:</h5>
            <ul>
              <li>Drill or screwdriver</li>
              <li>Stapler (for attaching canvas to quilt box)</li>
              <li>Approximate assembly time: 60 minutes</li>
            </ul>
          </div>
          <div role="tabpanel" class="tab-pane fade" id="feedback">
            <h5>Feedback</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
            <div class="review-star">
              <h6>Your Rating</h6>
              <i class="fa fa-star"></i><i class="fa fa-star"></i
              ><i class="fa fa-star"></i><i class="fa fa-star"></i
              ><i class="fa fa-star-half-empty"></i>
            </div>
            <div class="comment-form pt0">
              <form action="#">
                <div class="form-group">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <div class="col-12 col-lg-6">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Product Details End -->
    </div>
  </div>
  <!-- Product End -->
  <RelatedProducts />

  <Newsletter />
</template>

<script>
// @ is an alias to /src

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Newsletter from "@/components/Newsletter.vue";
import RelatedProducts from "@/components/RelatedProducts.vue";
import { auth, productsCollection } from "@/includes/firebase";

export default {
  name: "Product",
  data() {
    return {
      product: {},
    };
  },
  components: {
    Breadcrumbs,
    Newsletter,
    RelatedProducts,
  },
  async beforeRouteEnter(to, from, next) {
    const docSnapshot = await productsCollection.doc(to.params.id).get();

    next((vm) => {
      if (!docSnapshot.exists) {
        vm.$router.push({ name: "home" });
        return;
      }

      // eslint-disable-next-line no-param-reassign
      vm.product = docSnapshot.data();
      console.log(vm.product.images);
    });
  },
  mounted() {
    // eslint-disable-next-line no-undef
    $("a.gallery_link").on("click", function (event) {
      event.preventDefault();

      // var gallery = '#p-details-gallery'; //$(this).attr('id');
      // eslint-disable-next-line no-undef
      const gallery = $(this).attr("title");
      // eslint-disable-next-line no-undef
      $(gallery)
        .magnificPopup({
          delegate: "a",
          type: "image",
          gallery: {
            enabled: true,
          },
          zoom: {
            enabled: true,
            duration: 300, // don't foget to change the duration also in CSS
            opener(element) {
              return element.find("img");
            },
          },
        })
        .magnificPopup("open");
    });
    // eslint-disable-next-line no-undef
    $("#p-details-thumb-slide").owlCarousel({
      items: 5,
      loop: true,
      dots: false,
      nav: true,
      navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>",
      ],
      autoplay: false,
      smartSpeed: 1200,
      responsiveClass: true,
      touchDrag: true,
      responsive: {
        0: {
          items: 4,
          nav: false,
        },
        640: {
          items: 5,
          nav: false,
        },
      },
    });
    // eslint-disable-next-line no-undef
    $("#p-details-thumb-slide li").on("click", function () {
      // eslint-disable-next-line no-undef
      $("#p-details-thumb-slide li").removeClass("highlight");
      // eslint-disable-next-line no-undef
      $(this).toggleClass("highlight");
    });
    /* Zoom */

    // eslint-disable-next-line no-undef
    const easyzoom = $(".easyzoom").easyZoom();

    // Setup thumbnails example
    const api1 = easyzoom.filter(".easyzoom--with-thumbnails").data("easyZoom");
    // eslint-disable-next-line no-undef
    // eslint-disable-next-line no-undef
    $(".thumbnails").on("click", "a", function (e) {
      // eslint-disable-next-line no-undef
      const $this = $(this);

      e.preventDefault();

      // Use EasyZoom's `swap` method
      // eslint-disable-next-line no-undef
      api1.swap($this.data("standard"), $this.attr("href"));
    });
  },
};
</script>
